export const PlaygroundIcon = () => {
  return (
    <svg
      className="w-10 h-10"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path
          style={{ fill: '#45CCFF' }}
          d="M500.989,331.041v13.29c0,20.623-16.714,37.337-37.326,37.337H48.337 c-20.612,0-37.326-16.714-37.326-37.337v-13.29H500.989z"
        ></path>{' '}
        <path
          style={{ fill: '#EFEFEF' }}
          d="M500.989,68.567v262.474H11.011V68.567c0-20.612,16.714-37.326,37.326-37.326h415.326 C484.275,31.24,500.989,47.955,500.989,68.567z"
        ></path>{' '}
        <path
          style={{ fill: '#2C82C9' }}
          d="M333.075,480.76c-51.384,0-102.767,0-154.151,0c12.022-32.827,17.426-66.269,17.734-99.097 c39.561,0,79.121,0,118.683,0C315.651,414.492,321.055,447.932,333.075,480.76z"
        ></path>{' '}
        <g>
          {' '}
          <path
            style={{ fill: '#231F20' }}
            d="M463.663,20.23H48.337C21.683,20.23,0,41.914,0,68.567v275.764 c0,26.659,21.683,48.348,48.336,48.348h136.992c-1.233,26.663-6.004,52.508-14.226,77.07h-33.468 c-6.08,0-11.011,4.93-11.011,11.011c0,6.081,4.931,11.011,11.011,11.011h236.731c6.08,0,11.011-4.93,11.011-11.011 c0-6.081-4.931-11.011-11.011-11.011h-33.468c-8.222-24.564-12.993-50.409-14.226-77.07h136.991 c26.654,0,48.337-21.689,48.337-48.348V68.567C512,41.914,490.317,20.23,463.663,20.23z M22.022,68.567 c0-14.51,11.806-26.316,26.315-26.316h415.326c14.511,0,26.316,11.805,26.316,26.316V320.03H22.022V68.567z M317.757,469.75 H194.243c7.593-24.703,11.997-50.527,13.14-77.07h97.234C305.761,419.221,310.165,445.044,317.757,469.75z M489.979,344.332 c0,14.517-11.806,26.327-26.316,26.327H48.337c-14.51,0-26.315-11.81-26.315-26.327v-2.279H489.98v2.279H489.979z"
          ></path>{' '}
          <path
            style={{ fill: '#231F20' }}
            d="M402.818,275.99H165.734c-6.08,0-11.011,4.93-11.011,11.011c0,6.081,4.931,11.011,11.011,11.011 h237.085c6.08,0,11.011-4.93,11.011-11.011C413.829,280.919,408.899,275.99,402.818,275.99z"
          ></path>{' '}
          <path
            style={{ fill: '#231F20' }}
            d="M110.955,275.99h-1.773c-6.08,0-11.011,4.93-11.011,11.011c0,6.081,4.931,11.011,11.011,11.011 h1.772c6.08,0,11.011-4.93,11.011-11.011C121.965,280.919,117.035,275.99,110.955,275.99z"
          ></path>{' '}
          <path
            style={{ fill: '#231F20' }}
            d="M409.973,158.442L335.275,94.58c-4.62-3.952-11.572-3.408-15.524,1.214 c-3.952,4.622-3.409,11.572,1.214,15.524l64.908,55.493l-64.908,55.493c-4.622,3.952-5.165,10.902-1.214,15.524 c2.178,2.547,5.265,3.856,8.374,3.856c2.531,0,5.075-0.869,7.149-2.641l74.698-63.862c2.448-2.092,3.856-5.15,3.856-8.369 S412.421,160.534,409.973,158.442z"
          ></path>{' '}
          <path
            style={{ fill: '#231F20' }}
            d="M102.028,175.181l74.697,63.862c2.074,1.774,4.618,2.641,7.149,2.641 c3.107,0,6.197-1.308,8.374-3.856c3.952-4.622,3.409-11.572-1.214-15.524l-64.907-55.493l64.907-55.493 c4.622-3.952,5.165-10.902,1.214-15.524c-3.951-4.622-10.902-5.166-15.524-1.214l-74.697,63.862 c-2.448,2.092-3.856,5.15-3.856,8.369S99.58,173.089,102.028,175.181z"
          ></path>{' '}
          <path
            style={{ fill: '#231F20' }}
            d="M224.514,263.536c1.132,0.369,2.281,0.544,3.411,0.544c4.639,0,8.954-2.955,10.468-7.605 l56.155-172.509c1.883-5.782-1.279-11.996-7.061-13.878c-5.784-1.883-11.996,1.279-13.878,7.062l-56.155,172.509 C215.57,255.44,218.732,261.654,224.514,263.536z"
          ></path>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};
