import { Editor, Monaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { useContext, useState } from 'react';

type PlaygroundCodeEditorPanelProps = {
  input: string;
  setInput: (input: string) => void;
  handleRun: () => void;
  handleEditorDidMount: (
    editor: editor.IStandaloneCodeEditor,
    monaco: Monaco,
  ) => void;
  onFormatCodeClick: () => void;
};

export const PlaygroundCodeEditorPanel: React.FC<
  PlaygroundCodeEditorPanelProps
> = ({
  input,
  setInput,
  handleRun,
  handleEditorDidMount,
  onFormatCodeClick,
}) => {
  const [activeTab, setActiveTab] = useState('dax-query');
  return (
    <div className="p-1 h-full flex flex-col" style={{ width: '100%' }}>
      {/* Tabs */}
      <div role="tablist" className="tabs tabs-bordered">
        <button
          className={`tab ${activeTab === 'dax-query' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="DAX Query"
          onClick={() => setActiveTab('dax-query')}
        >
          DAX Query
        </button>
      </div>

      {/* Main Content Area (Editor and Buttons) */}
      <div className="flex-grow flex flex-col overflow-hidden">
        {/* Tab Panels */}
        <div className="flex-grow relative">
          <div
            role="tabpanel"
            className={`absolute inset-0 ${activeTab === 'dax-query' ? 'block' : 'hidden'}`}
          >
            <Editor
              key="dax-query"
              onMount={handleEditorDidMount}
              width="100%"
              height="100%"
              defaultLanguage="dax"
              defaultValue={input || '// Type your DAX Query here'}
              onChange={(value) => setInput(value ?? '')}
              theme="vs-dark"
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between space-x-4 mt-2">
          <button
            type="button"
            className="flex-1 focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm py-2.5 dark:bg-green-600 dark:hover:bg-green-700"
            onClick={handleRun}
          >
            Run
          </button>
          <button
            type="button"
            className="flex-1 focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm py-2.5 dark:bg-green-600 dark:hover:bg-green-700"
            onClick={onFormatCodeClick}
          >
            Format Code
          </button>
          {/* <button
            type="button"
            className="flex-1 focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm py-2.5 dark:bg-green-600 dark:hover:bg-green-700"
            onClick={onFormatCodeClick}
          >
            AI Help
          </button> */}
        </div>
      </div>
    </div>
  );
};
