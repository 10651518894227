import React, { useState } from 'react';
import { IQuestion } from '../../models/Question';
import sanitizeHtml from 'sanitize-html';

import { ReactFlow } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { DataTablesViewContainer } from '../DataTablesView/container';
import { AutoQuestionAdjusterNav } from '../AutoQuestionAdjusterNav/container';
import { useSolverContext } from '../../contexts/Solver';

const initialNodes = [
  { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
  { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
];
const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];

// Will support Markdown for better formatting.
export const QuestionDescription: React.FC<{ question: IQuestion }> = ({
  question,
}) => {
  const [activeTab, setActiveTab] = useState<
    'question-description' | 'data-model-tables' | 'data-model-relationships'
  >('question-description');
  const { onTablePreviewClick } = useSolverContext();
  const sanitizedHtml = sanitizeHtml(question?.description ?? '');

  return (
    <div className="flex flex-col h-full">
      <AutoQuestionAdjusterNav />
      {/* Title */}
      <h2 className="text-2xl font-bold prose pb-4">
        {question?.title ?? 'Problem with Unknown Title'}
      </h2>

      {/* Tabs */}
      <div role="tablist" className="tabs tabs-bordered">
        <button
          className={`tab ${activeTab === 'question-description' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="Question Description"
          onClick={() => setActiveTab('question-description')}
        >
          Description
        </button>
        <button
          className={`tab ${activeTab === 'data-model-tables' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="Data Tables"
          onClick={() => setActiveTab('data-model-tables')}
        >
          Tables
        </button>
        <button
          className={`tab ${activeTab === 'data-model-relationships' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="Table Relationships"
          onClick={() => setActiveTab('data-model-relationships')}
        >
          Relationships
        </button>
      </div>

      {/* Tab Panels */}
      <div className="flex-1 relative overflow-hidden">
        {/* Question Description Tab */}
        <div
          className={`absolute inset-0 ${activeTab === 'question-description' ? 'block' : 'hidden'}`}
          role="tabpanel"
        >
          <div className="h-full overflow-y-scroll p-4 prose">
            <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
          </div>
        </div>

        {/* Question Data Tables Tab */}
        <div
          className={`absolute inset-0 ${activeTab === 'data-model-tables' ? 'block' : 'hidden'}`}
          role="tabpanel"
        >
          <div className="h-full overflow-y-auto">
            <DataTablesViewContainer
              tables={question.tabular_model.tables}
              onTablePreview={onTablePreviewClick}
            />
          </div>
        </div>

        {/* Question Data Relationships Tab */}
        <div
          className={`absolute inset-0 ${activeTab === 'data-model-relationships' ? 'block' : 'hidden'}`}
          role="tabpanel"
        >
          <div className="h-full p-4">
            <ReactFlow
              nodes={initialNodes}
              edges={initialEdges}
              colorMode="dark"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
