import react, { useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { ReactFlow } from '@xyflow/react';

import '@xyflow/react/dist/style.css';
import { DataTablesViewContainer } from '../DataTablesView/container';
import { CodePlaygroundContext } from '../../contexts/CodePlayground';

const initialNodes = [
  { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
  { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
];
const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];

// Will support Markdown for better formatting.
export const PlaygroundMetadataPanel: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    | 'semantic-model-description'
    | 'data-model-tables'
    | 'data-model-relationships'
  >('data-model-tables');
  const { currentPlayground, onTablePreview } = useContext(
    CodePlaygroundContext,
  );

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center space-x-4 justify-between w-full">
        {/* Title */}
        <h2 className="text-2xl font-bold dark:text-white pb-4 mx-auto">
          {currentPlayground.name}
        </h2>

        {/* Dropdown for switching models */}
        {/* We are not yet supporting switching models */}
        <select
          className="select-info text-white p-2 rounded"
          defaultValue={currentPlayground.tabular_model.modelId}
          disabled
        >
          <option value={currentPlayground.tabular_model.modelId} disabled>
            {currentPlayground.tabular_model.modelName}
          </option>
          {/* <option value="" disabled>
            Switch Semantic models
          </option>
          {dataModelSwitchOptions.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))} */}
        </select>
      </div>

      {/* Tabs */}
      <div role="tablist" className="tabs tabs-bordered">
        {currentPlayground.description && (
          <button
            className={`tab ${activeTab === 'semantic-model-description' ? 'tab-active' : ''}`}
            role="tab"
            aria-label="Model Description"
            onClick={() => setActiveTab('semantic-model-description')}
          >
            Description
          </button>
        )}
        <button
          className={`tab ${activeTab === 'data-model-tables' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="Data Tables"
          onClick={() => setActiveTab('data-model-tables')}
        >
          Tables
        </button>
        <button
          className={`tab ${activeTab === 'data-model-relationships' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="Table Relationships"
          onClick={() => setActiveTab('data-model-relationships')}
        >
          Relationships
        </button>
      </div>

      {/* Tab Panels */}
      <div className="flex-1 relative overflow-hidden">
        {currentPlayground.tabular_model.modelDescription && (
          <div
            className={`absolute inset-0 ${activeTab === 'semantic-model-description' ? 'block' : 'hidden'}`}
            role="tabpanel"
          >
            <div className="h-full overflow-y-auto p-4">
              <ReactMarkdown
                children={currentPlayground.tabular_model.modelDescription}
                remarkPlugins={[remarkGfm]}
              />
            </div>
          </div>
        )}

        {/* Question Data Relationships Tab */}
        <div
          className={`absolute inset-0 ${activeTab === 'data-model-tables' ? 'block' : 'hidden'}`}
          role="tabpanel"
        >
          <div className="h-full overflow-y-auto">
            <DataTablesViewContainer
              tables={currentPlayground.tabular_model.tables}
              onTablePreview={onTablePreview}
            />
          </div>
        </div>

        {/* Question Data Relationships Tab */}
        <div
          className={`absolute inset-0 ${activeTab === 'data-model-relationships' ? 'block' : 'hidden'}`}
          role="tabpanel"
        >
          <div className="h-full p-4">
            <ReactFlow
              nodes={initialNodes}
              edges={initialEdges}
              colorMode="dark"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
