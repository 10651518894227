import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetAllCourses } from '../hooks/useGetCourses';

export const CourseList = () => {
  const { data: courses } = useGetAllCourses();
  const navigate = useNavigate();

  return (
    <div className="container mx-auto p-4">
      {/* Page Title */}
      <h1 className="text-3xl font-bold text-center mb-6 prose">Course List</h1>

      {/* Courses Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {courses.map((course) => (
          <div
            key={course.id}
            className="card w-72 bg-base-100 shadow-xl w-full"
          >
            <div className="card-body">
              <h2 className="card-title text-primary">{course.title}</h2>
              <p className="prose">{course.description}</p>
              <div className="card-actions justify-end">
                <Link
                  className={`btn btn-primary ${!course.questions || course.questions?.length === 0 ? 'btn-disabled' : ''}`}
                  to={
                    course.questions && course.questions.length > 0
                      ? `/questions/${course.questions[0].id}`
                      : '#'
                  }
                >
                  Start Course
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
