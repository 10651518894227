export const PricingIcon = () => {
  return (
    <svg
      viewBox="-4.89 -4.89 58.67 58.67"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      className="w-10 h-10"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0">
        <path
          transform="translate(-4.89, -4.89), scale(1.8334375)"
          d="M16,30.881296760941808C18.701197215564928,30.4380362510261,20.84171879404674,28.457655165797426,22.73636354664056,26.481986281977278C24.325893775640186,24.82448008772804,24.77811975035826,22.52227739853826,25.92450671975942,20.532370353155134C27.226169931839102,18.272933648473796,30.749029782145147,16.47253411336829,29.928775241151726,13.997344458387783C29.04629415379646,11.334380769711018,24.891240895152173,11.865711510364633,22.54353538356145,10.32999431387394C21.041478657038716,9.3474458921758,20.19399833306264,7.725732659026195,18.737548183098173,6.676767086978103C16.628003011555723,5.1574289498765316,14.72811218264476,2.9988761221860862,12.133708853700572,2.8326215812175324C9.25254681027268,2.6479909697616564,6.16275811127618,3.660883077722982,4.162526248253785,5.742770605262864C2.1359889468249817,7.852037440740586,1.7889171291527168,11.003949694407865,1.4875204040383618,13.913420442055774C1.1955929186245244,16.731481849072424,1.3814266503728514,19.563735381768005,2.459374080939117,22.183796657271362C3.565636853963794,24.87268126327696,5.228939672747748,27.382104846402534,7.6753089292756105,28.953472151684608C10.121385186462575,30.52465125578506,13.131155724500971,31.35206778391597,16,30.881296760941808"
          fill="#7ed0ec"
          strokeWidth="0"
        ></path>
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <g
          id="red_dimond_premium"
          data-name="red dimond premium"
          transform="translate(-229.078 -417.619)"
        >
          {' '}
          <path
            id="Path_140"
            data-name="Path 140"
            d="M240.523,432.087l-7.982,9.977L250.5,466.01l17.959-23.946-7.982-9.977Z"
            fill="#e23a44"
            fillRule="evenodd"
          ></path>{' '}
          <path
            id="Path_141"
            data-name="Path 141"
            d="M238.527,421.328l2.993,5.184m-11.759,3.58,5.186,2.993m36.29-2.993-5.184,2.993m-3.58-11.757-2.993,5.184m-8.98-8.393v5.986m17.959,17.96H232.541m16.273-9.978-1.842,9.978L250.5,466.01l3.528-23.945-1.842-9.978Zm-3.668,0-4.671,9.978L250.5,466.01l10.025-23.945-4.671-9.978Zm17.327,17.96,5.986-7.982-7.982-9.978H240.523l-7.982,9.978,5.986,7.982m2.993,3.991,8.98,11.972,8.98-11.972"
            fill="none"
            stroke="#0f0e0b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          ></path>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};
