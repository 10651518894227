import { Editor, Monaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { useState } from 'react';
import { IQuestion } from '../../models/Question';

type SolverCodeEditorPanelProps = {
  input: string;
  setInput: (input: string) => void;
  handleRun: (submit: boolean) => void;
  handleEditorDidMount: (
    editor: editor.IStandaloneCodeEditor,
    monaco: Monaco,
  ) => void;
  onFormatCodeClick: () => void;
  questionInfo: IQuestion;
};

export const SolverCodeEditorPanel: React.FC<SolverCodeEditorPanelProps> = ({
  input,
  setInput,
  handleRun,
  handleEditorDidMount,
  onFormatCodeClick,
  questionInfo,
}) => {
  const [activeTab, setActiveTab] = useState('dax-expression');
  const daxQueryWrappers = questionInfo.dax_query_template.split(
    '{_insect_correct_answer_here}',
  );
  const daxQueryValue =
    daxQueryWrappers[0] +
    '\r\n// ---------------------------------- \r\n' +
    input +
    '\r\n// ----------------------------------' +
    daxQueryWrappers[1];
  return (
    <div className="p-1 h-full flex flex-col" style={{ width: '100%' }}>
      {/* Tabs */}
      <div role="tablist" className="tabs tabs-bordered">
        <button
          className={`tab ${activeTab === 'dax-expression' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="DAX Expression"
          onClick={() => setActiveTab('dax-expression')}
        >
          DAX Expression
        </button>
        <button
          className={`tab ${activeTab === 'dax-query' ? 'tab-active' : ''}`}
          role="tab"
          aria-label="DAX Query"
          onClick={() => setActiveTab('dax-query')}
        >
          DAX Query
        </button>
      </div>

      {/* Main Content Area (Editor and Buttons) */}
      <div className="flex-grow flex flex-col overflow-hidden">
        {/* Tab Panels */}
        <div className="flex-grow relative">
          <div
            role="tabpanel"
            className={`absolute inset-0 ${activeTab === 'dax-expression' ? 'block' : 'hidden'}`}
          >
            <Editor
              key="dax-expression"
              onMount={handleEditorDidMount}
              width="100%"
              height="100%"
              defaultLanguage="dax"
              defaultValue={'// DAX Expression goes here'}
              onChange={(value) => setInput(value ?? '')}
              theme="vs-dark"
            />
          </div>

          <div
            role="tabpanel"
            className={`absolute inset-0 ${activeTab === 'dax-query' ? 'block' : 'hidden'}`}
          >
            <Editor
              key="dax-query"
              onMount={handleEditorDidMount}
              width="100%"
              height="100%"
              defaultLanguage="dax"
              defaultValue="// DAX Measure will appear here"
              value={daxQueryValue}
              theme="vs-dark"
              options={{ domReadOnly: true, readOnly: true }}
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between space-x-2 mt-2 w-full">
          <button
            type="button"
            className="flex-1 w-full focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-3 py-2 dark:bg-green-600 dark:hover:bg-green-700"
            onClick={() => handleRun(false)}
          >
            Run
          </button>

          <button
            type="button"
            className="flex-1 w-full focus:outline-none text-white bg-blue-600 hover:bg-blue-700 font-medium rounded-lg text-sm px-3 py-2 dark:bg-blue-500 dark:hover:bg-blue-600"
            onClick={onFormatCodeClick}
          >
            Format Code
          </button>

          <button
            type="button"
            className="flex-1 w-full focus:outline-none text-white bg-yellow-600 hover:bg-yellow-700 font-medium rounded-lg text-sm px-3 py-2 dark:bg-yellow-500 dark:hover:bg-yellow-600"
            onClick={() => handleRun(true)}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
