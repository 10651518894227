import React from 'react';
import { ICourseInfo } from '../../models/Courses';
import { Link } from 'react-router-dom';

export const DashboardTopCourses: React.FC<{
  courses: ICourseInfo[];
  onSeeMoreClick: () => void;
}> = ({ courses, onSeeMoreClick }) => {
  return (
    <div className="flex flex-col lg:flex-row gap-6 mb-8 flex-wrap">
      {courses.slice(0, 3).map((course) => {
        return (
          <div
            className="card w-5/5 lg:w-72 bg-base-100 shadow-xl"
            key={course.id}
          >
            <div className="card-body">
              <h2 className="card-title text-primary">{course.title}</h2>
              <p className="prose">{course.description}</p>
              <div className="card-actions justify-end">
                <Link
                  className={`btn btn-primary ${!course.questions || course.questions?.length === 0 ? 'btn-disabled' : ''}`}
                  to={
                    course.questions && course.questions.length > 0
                      ? `/questions/${course.questions[0].id}`
                      : '#'
                  }
                >
                  Explore Problems
                </Link>
              </div>
            </div>
          </div>
        );
      })}

      <div className="relative hidden lg:flex items-center justify-center w-12 bg-base-100 shadow-xl rounded-md">
        <button
          className="w-full h-full text-gray-500 hover:text-primary focus:outline-none"
          onClick={onSeeMoreClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 mx-auto"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
        <span className="absolute left-1/2 transform -translate-x-1/2 bottom-2 text-xs text-gray-500 opacity-0 hover:opacity-100">
          See all
        </span>
      </div>
    </div>
  );
};
