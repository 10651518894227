import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import AuthComponentContainer from '../Auth/container';
import { useAuth } from '../../auth/authContext';
import { ThemeSwitcher } from './themeSwitcher';
import { useGetUserSemanticModels } from '../../hooks/useGetUserSemanticModels';
import { useGetUserPlaygrounds } from '../../hooks/useGetUserPlaygrounds';
import { useGetPlans } from '../../hooks/useGetPlans';
import { HomeIcon } from '../../assets/svgs/HomeIcon';
import { PlaygroundIcon } from '../../assets/svgs/PlaygroundIcon';
import { ManageModelsIcon } from '../../assets/svgs/ManageModelsIcon';
import { ModelPlaygroundsIcon } from '../../assets/svgs/ModelPlaygroundsIcon';
import { PricingIcon } from '../../assets/svgs/PricingIcon';
import { DaxUserIcon } from '../../assets/svgs/DaxUserIcon';

export const SideNavBar: React.FC = () => {
  const { daxSolverUser, isAuthenticated } = useAuth();
  const linkHoverRef = useRef<{
    semanticModelsLink: boolean;
    playgroundsLink: boolean;
    plansLink: boolean;
  }>({ semanticModelsLink: false, playgroundsLink: false, plansLink: false });
  const { refetch: getSemanticModels } = useGetUserSemanticModels({
    enabled: false,
  });
  const { refreshPlaygrounds: getUserPlaygrounds } = useGetUserPlaygrounds({
    enabled: false,
  });
  const { refetch: getSubscriptionPlans } = useGetPlans({ enabled: false });

  return (
    <ul className="menu menu-lg hidden bg-base-200 rounded-box fixed top-0 left-0 w-[120px] h-screen z-10 p-4 lg:flex lg:justify-between">
      <div>
        <li>
          <Link className="tooltip tooltip-right" data-tip="Home" to="/">
            <HomeIcon />
          </Link>
        </li>

        <li>
          <Link
            className="tooltip tooltip-right"
            data-tip="Dax Playground"
            to="/playgrounds"
            onMouseEnter={() => {
              !linkHoverRef.current.playgroundsLink
                ? getUserPlaygrounds().then((resp) => {
                    linkHoverRef.current.playgroundsLink = true;
                  })
                : void null;
            }}
          >
            <PlaygroundIcon />
          </Link>
        </li>
        <li>
          <Link
            className="tooltip tooltip-right"
            data-tip="Manage Models"
            to="/manage-models"
            onMouseEnter={() => {
              !linkHoverRef.current.semanticModelsLink
                ? getSemanticModels().then((resp) => {
                    linkHoverRef.current.semanticModelsLink = true;
                  })
                : void null;
            }}
          >
            <ManageModelsIcon />
          </Link>
        </li>
        {/* <li>
        <a
          className="tooltip tooltip-right"
          data-tip="Dax Jobs"
          href="/jobs"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            className="h-10 w-10"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 367.004 367.004"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {' '}
              <g>
                {' '}
                <path d="M160.937,197.203c24.125,0,80.846-29.033,80.846-98.603c0-9.705-0.236-19.081-1.037-27.94 c-1.313-17.427-4.883-32.872-13.768-44.71C215.578,9.838,195.629,0,160.937,0c-34.68,0-54.628,9.833-66.029,25.937 C86.012,37.778,82.44,53.231,81.127,70.669c-0.799,8.856-1.035,18.229-1.035,27.932C80.092,168.17,136.812,197.203,160.937,197.203 z M187.328,36.123c-2.355,5.951-3.535,8.926-5.891,14.877c-15.209-4.297-25.791-4.297-41,0c-2.355-5.951-3.533-8.926-5.891-14.877 C153.566,30.365,168.308,30.365,187.328,36.123z M92.119,83.517c0.932,2.037,4.889,9.869,11.014,10.604 c7.041,0.844,18.729-24.936,57.805-24.998c39.076,0.063,50.764,25.842,57.805,24.998c6.125-0.735,10.082-8.567,11.014-10.604 c0.189,4.992,0.259,10.046,0.259,15.084c0,34.006-15.015,55.076-27.612,66.763c-15.871,14.726-33.492,20.071-41.465,20.071 c-7.973,0-25.594-5.345-41.465-20.071c-12.598-11.687-27.612-32.757-27.612-66.763C91.86,93.562,91.93,88.509,92.119,83.517z"></path>{' '}
                <path d="M232.705,211.768c2.605-2.606,5.371-4.994,8.26-7.181c-11.223-6.117-22.109-11.933-29.153-15.697l-0.331-0.177 c-1.982-1.059-4.403-0.847-6.169,0.541c-9.085,7.132-19.035,11.938-29.574,14.283c-1.861,0.414-3.391,1.738-4.066,3.521 l-10.734,28.291l-10.734-28.291c-0.675-1.783-2.204-3.106-4.066-3.521c-10.539-2.346-20.489-7.152-29.573-14.283 c-1.769-1.388-4.188-1.601-6.17-0.541c-17.138,9.158-58.25,31.302-69.829,39.106c-19.621,13.219-28.199,61.053-29.72,70.508 c-0.15,0.938-0.064,1.898,0.253,2.793c0.703,1.982,18.709,48.548,149.84,48.548c5.933,0,11.627-0.098,17.105-0.28 c0.481-6.064,3.029-11.993,7.657-16.622l31.512-31.511C203.113,271.581,208.833,235.64,232.705,211.768z"></path>{' '}
                <path d="M336.396,219.546c-12.79-12.79-29.821-19.834-47.957-19.834c-18.135,0-35.166,7.044-47.955,19.834 c-21.512,21.511-26.052,54.982-11.04,81.396l1.317,2.319l-37.284,37.284c-6.042,6.043-6.041,15.876,0.002,21.92 c2.927,2.927,6.819,4.539,10.958,4.539c4.142,0,8.033-1.612,10.96-4.54l37.285-37.283l2.317,1.315 c10.126,5.748,21.698,8.787,33.464,8.787c18.127,0,35.149-7.04,47.933-19.824c12.791-12.79,19.835-29.822,19.835-47.957 C356.231,249.367,349.187,232.336,336.396,219.546z M319.779,298.842c-8.357,8.358-19.488,12.962-31.339,12.962 c-11.851,0-22.982-4.603-31.341-12.962c-17.281-17.281-17.281-45.399,0-62.68c8.359-8.359,19.49-12.962,31.341-12.962 c11.851,0,22.981,4.604,31.339,12.963c8.359,8.358,12.963,19.488,12.963,31.339S328.138,290.483,319.779,298.842z"></path>{' '}
              </g>{' '}
            </g>
          </svg>
        </a>
      </li> */}
        <li>
          <a
            href="https://model.daxsolver.com"
            data-tip="Model Playground"
            target="_blank"
            rel="noopener noreferrer"
            className="tooltip tooltip-right"
          >
            <ModelPlaygroundsIcon />
          </a>
        </li>

        <li>
          <Link
            className="tooltip tooltip-right"
            data-tip="Pricing"
            to="/pricing"
            onMouseEnter={() => {
              !linkHoverRef.current.plansLink
                ? getSubscriptionPlans().then(
                    () => (linkHoverRef.current.plansLink = true),
                  )
                : void null;
            }}
          >
            <PricingIcon />
          </Link>
        </li>
        {daxSolverUser && (
          <li>
            <button className="flex flex-col">
              <DaxUserIcon />
              <span className="badge badge-primary uppercase text-white">
                {daxSolverUser.subscription_plan.slice(0, 3)}{' '}
                {/* Temporary measure to show current plan. Figure out a better label. */}
              </span>
            </button>
          </li>
        )}
      </div>
      <div>
        <li>
          <ThemeSwitcher />
        </li>
        <li>
          <div
            className="rounded-lg text-white tooltip tooltip-right"
            data-tip={!isAuthenticated ? 'Sign in with Google' : 'Sign out'}
          >
            <AuthComponentContainer />
          </div>
        </li>
      </div>
    </ul>
  );
};
