import React from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon } from '../../assets/svgs/HomeIcon';
import { PlaygroundIcon } from '../../assets/svgs/PlaygroundIcon';
import { ManageModelsIcon } from '../../assets/svgs/ManageModelsIcon';
import { ModelPlaygroundsIcon } from '../../assets/svgs/ModelPlaygroundsIcon';
import { PricingIcon } from '../../assets/svgs/PricingIcon';
import AuthComponentContainer from '../Auth/container';
import { useAuth } from '../../auth/authContext';

export const TopNavBar: React.FC = () => {
  const { isAuthenticated } = useAuth();
  return (
    <nav className="bg-base-200 fixed bottom-0 left-0 w-full z-10 p-4 lg:hidden">
      <ul className="flex justify-around">
        <li>
          <Link className="tooltip tooltip-top" data-tip="Home" to="/">
            <HomeIcon />
          </Link>
        </li>
        <li>
          <Link
            className="tooltip tooltip-top"
            data-tip="Playgrounds"
            to="/playgrounds"
          >
            <PlaygroundIcon />
          </Link>
        </li>
        <li>
          <Link
            className="tooltip tooltip-top"
            data-tip="Manage Models"
            to="/manage-models"
          >
            <ManageModelsIcon />
          </Link>
        </li>
        <li>
          <a
            href="https://model.daxsolver.com"
            data-tip="Model Playground"
            target="_blank"
            rel="noopener noreferrer"
            className="tooltip tooltip-top"
          >
            <ModelPlaygroundsIcon />
          </a>
        </li>
        <li>
          <Link
            className="tooltip tooltip-top"
            data-tip="Pricing"
            to="/pricing"
          >
            <PricingIcon />
          </Link>
        </li>
        <li>
          <div
            className="rounded-lg text-white tooltip tooltip-top"
            data-tip={!isAuthenticated ? 'Sign in with Google' : 'Sign out'}
          >
            <AuthComponentContainer />
          </div>
        </li>
      </ul>
    </nav>
  );
};
