import React from 'react';
import {
  IExecResult,
  IPlaygroundExecutionResult,
} from '../../models/ExecutionResult';

// Should be expanded to dyamically handle different types of result outputs
// Some can be tables, others a single value etc.
export interface ResultsPanelProps {
  results: IExecResult | null;
}
export const ResultsPanel: React.FC<ResultsPanelProps> = ({ results }) => {
  if (!results) {
    return <p className="prose">No results yet</p>;
  }
  const headers =
    results.code_evaluation_result?.length > 0
      ? Object.keys(results.code_evaluation_result[0])
      : [];

  return (
    <div>
      <h3 className="prose">Results</h3>
      {results.error ? (
        <p className="prose">❌ {results.error}</p>
      ) : (
        headers && (
          <div className="rounded-lg w-screen">
            {/* Evaluation correct or not - only applicable in results panel for problems and not for playgrounds. */}
            <p className="prose">
              {(results.correct ?? ' ') ||
                (results.correct
                  ? '✅ Well done!'
                  : '❌ Evaluation did not pass')}
            </p>
            <table className="block overflow-auto whitespace-nowrap">
              <thead className="text-sm text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {results.code_evaluation_result.map((result, index) => (
                  <tr key={index}>
                    {headers.map((header) => (
                      <td key={`${header}-${index}`}>{result[header]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      )}
    </div>
  );
};
