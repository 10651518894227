export const ModelPlaygroundsIcon = () => {
  return (
    <svg
      fill="#000000"
      className="w-10 h-10"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="-5.5 -5.5 66.00 66.00"
      xmlSpace="preserve"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0">
        <path
          transform="translate(-5.5, -5.5), scale(2.0625)"
          d="M16,31.733256886713207C20.116828352725594,31.696150473513963,24.427106269803804,30.16880601777781,26.815206429257756,26.81520642925776C28.990499469268116,23.760451035096523,27.440096009894358,19.74266333845093,27.203661107458174,16C26.99227139792532,12.653791348728877,27.94518013395012,8.795659664800535,25.529944230574955,6.4700557694250485C23.130482706836105,4.159640849452783,19.32633480735177,5.3879865560536,16,5.212118833791465C12.333479201283543,5.01826502807358,8.170784422570174,2.9900162908377075,5.406080466811341,5.406080466811337C2.6043509867799384,7.854501106342395,3.727395423161917,12.27988290074446,3.655419905204326,15.999999999999998C3.5810668501744303,19.843002165841792,2.6153762032377865,23.987942178727476,4.988057849755458,27.011942150244536C7.5509221267432,30.27833126400856,11.848353233940161,31.770677130444458,16,31.733256886713207"
          fill="#7ed0ec"
          strokeWidth="0"
        ></path>
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path d="M49,0c-3.309,0-6,2.691-6,6c0,1.035,0.263,2.009,0.726,2.86l-9.829,9.829C32.542,17.634,30.846,17,29,17 s-3.542,0.634-4.898,1.688l-7.669-7.669C16.785,10.424,17,9.74,17,9c0-2.206-1.794-4-4-4S9,6.794,9,9s1.794,4,4,4 c0.74,0,1.424-0.215,2.019-0.567l7.669,7.669C21.634,21.458,21,23.154,21,25s0.634,3.542,1.688,4.897L10.024,42.562 C8.958,41.595,7.549,41,6,41c-3.309,0-6,2.691-6,6s2.691,6,6,6s6-2.691,6-6c0-1.035-0.263-2.009-0.726-2.86l12.829-12.829 c1.106,0.86,2.44,1.436,3.898,1.619v10.16c-2.833,0.478-5,2.942-5,5.91c0,3.309,2.691,6,6,6s6-2.691,6-6c0-2.967-2.167-5.431-5-5.91 v-10.16c1.458-0.183,2.792-0.759,3.898-1.619l7.669,7.669C41.215,39.576,41,40.26,41,41c0,2.206,1.794,4,4,4s4-1.794,4-4 s-1.794-4-4-4c-0.74,0-1.424,0.215-2.019,0.567l-7.669-7.669C36.366,28.542,37,26.846,37,25s-0.634-3.542-1.688-4.897l9.665-9.665 C46.042,11.405,47.451,12,49,12c3.309,0,6-2.691,6-6S52.309,0,49,0z M11,9c0-1.103,0.897-2,2-2s2,0.897,2,2s-0.897,2-2,2 S11,10.103,11,9z M6,51c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S8.206,51,6,51z M33,49c0,2.206-1.794,4-4,4s-4-1.794-4-4 s1.794-4,4-4S33,46.794,33,49z M29,31c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S32.309,31,29,31z M47,41c0,1.103-0.897,2-2,2 s-2-0.897-2-2s0.897-2,2-2S47,39.897,47,41z M49,10c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S51.206,10,49,10z"></path>{' '}
      </g>
    </svg>
  );
};
