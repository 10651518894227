export const ManageModelsIcon = () => {
  return (
    <svg
      viewBox="-102.4 -102.4 1228.80 1228.80"
      className="icon w-10 h-10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0">
        <path
          transform="translate(-102.4, -102.4), scale(38.4)"
          d="M16,31.43524600425735C19.09614350396777,31.178508005523877,20.517788400280548,27.50238770428351,22.989036166273127,25.619583020777497C25.375327370200424,23.801505401531205,29.26513268109411,23.46443272372609,30.202420265639894,20.614646078483787C31.139586940812467,17.765227053513794,28.65291821037813,15.016703465907923,27.36770539042363,12.306408617678384C26.26590175160704,9.982892481143978,25.10801575582648,7.792313855115458,23.26952670763097,5.994354869234401C21.140749881291192,3.91250758947517,18.96295598353332,1.4837067245812599,16.000000000000004,1.1893078247085214C12.93749949733759,0.885018195077111,9.854198948831298,2.342912642670048,7.599249016955726,4.43735822855742C5.485076370448397,6.401046869572194,4.8214475177410865,9.346872503454382,4.186801012226137,12.161658973353639C3.608258794884213,14.727611502026637,3.4426451840309396,17.31827047832062,4.122065556599251,19.859374851224143C4.8328300856822946,22.51770969939804,6.144197901734548,24.932441446496846,8.114849689029127,26.852978328217958C10.346550986013701,29.027926067578782,12.89442888916157,31.692765757750482,16,31.43524600425735"
          fill="#7ed0ec"
          strokeWidth="0"
        ></path>
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M469.572424 567.833954c-10.976147-6.337475-27.610249-7.026433-37.11882-1.53836L122.346765 745.339871c-9.508571 5.488074-8.314691 15.091023 2.661456 21.428498l428.617143 247.46352c10.980866 6.337475 27.614968 7.026433 37.123539 1.53836l310.106839-179.039558c9.508571-5.488074 8.314691-15.091023-2.666175-21.433216l-428.617143-247.463521z"
          fill="#4762AF"
        ></path>
        <path
          d="M453.301677 881.79141c5.219097 3.015373 9.461382 10.357972 9.461383 16.388719s-4.242286 8.479853-9.461383 5.464479c-5.223816-3.015373-9.461382-10.357972-9.461382-16.388719s4.237567-8.479853 9.461382-5.464479zM491.208553 903.677641c5.219097 3.015373 9.461382 10.357972 9.461382 16.388718s-4.242286 8.475134-9.461382 5.459761c-5.223816-3.010654-9.461382-10.357972-9.461382-16.384 0-6.030747 4.237567-8.479853 9.461382-5.464479zM529.11071 925.563871c5.223816 3.015373 9.461382 10.357972 9.461382 16.388719 0 6.026028-4.237567 8.475134-9.461382 5.45976-5.219097-3.015373-9.461382-10.357972-9.461383-16.388719 0-6.026028 4.242286-8.475134 9.461383-5.45976z"
          fill="#448FFF"
        ></path>
        <path
          d="M116.004571 627.905475s-0.962654 128.495484 0.132129 128.320884c1.090065-0.16988 52.979023 26.741972 52.979024 26.741973l52.922396-53.262157 3.270193-38.237198-109.303742-63.563502zM907.273438 699.986581v127.395981l-80.225917 46.660424-41.34695-98.26153 121.572867-75.794875z"
          fill="#4762AF"
        ></path>
        <path
          d="M469.572424 440.400221c-10.976147-6.337475-27.610249-7.026433-37.11882-1.538359L122.346765 617.901419c-9.508571 5.492793-8.314691 15.095742 2.661456 21.433217l428.617143 247.463521c10.980866 6.337475 27.614968 7.026433 37.123539 1.538359l310.106839-179.039557c9.508571-5.492793 8.314691-15.095742-2.666175-21.433217l-428.617143-247.463521z"
          fill="#6D8ACA"
        ></path>
        <path
          d="M511.443171 825.155244s48.736737 34.849032 78.069382 18.64435c29.332645-16.209401 288.022415-167.860498 288.022415-167.860497l-0.235945-44.277383-365.855852 140.637198v52.856332z"
          fill="#5D7BC1"
        ></path>
        <path
          d="M199.175078 592.008848v52.856332l312.423816 180.379723V772.388571l-312.423816-180.379723z"
          fill="#5D7BC1"
        ></path>
        <path
          d="M373.3353 846.55071c0-6.030747-3.6713-13.047742-8.192-15.657291L157.360959 710.934415c-4.5207-2.614267-8.192 0.160442-8.192 6.191189s3.6713 13.047742 8.192 15.65729l207.782341 119.959005c4.5207 2.614267 8.192-0.160442 8.192-6.191189z"
          fill="#6D8ACA"
        ></path>
        <path
          d="M469.572424 567.833954c-10.976147-6.337475-27.610249-7.026433-37.11882-1.53836L122.346765 745.339871c-9.508571 5.488074-8.314691 15.091023 2.661456 21.428498l428.617143 247.46352c10.980866 6.337475 27.614968 7.026433 37.123539 1.53836l310.106839-179.039558c9.508571-5.488074 8.314691-15.091023-2.666175-21.433216l-428.617143-247.463521z"
          fill="#4762AF"
        ></path>
        <path
          d="M453.301677 881.79141c5.219097 3.015373 9.461382 10.357972 9.461383 16.388719s-4.242286 8.479853-9.461383 5.464479c-5.223816-3.015373-9.461382-10.357972-9.461382-16.388719s4.237567-8.479853 9.461382-5.464479zM491.208553 903.677641c5.219097 3.015373 9.461382 10.357972 9.461382 16.388718s-4.242286 8.475134-9.461382 5.459761c-5.223816-3.010654-9.461382-10.357972-9.461382-16.384 0-6.030747 4.237567-8.479853 9.461382-5.464479zM529.11071 925.563871c5.223816 3.015373 9.461382 10.357972 9.461382 16.388719 0 6.026028-4.237567 8.475134-9.461382 5.45976-5.219097-3.015373-9.461382-10.357972-9.461383-16.388719 0-6.026028 4.242286-8.475134 9.461383-5.45976z"
          fill="#448FFF"
        ></path>
        <path
          d="M116.004571 627.905475s-0.962654 128.495484 0.132129 128.320884c1.090065-0.16988 52.979023 26.741972 52.979024 26.741973l52.922396-53.262157 3.270193-38.237198-109.303742-63.563502zM907.273438 699.986581v127.395981l-80.225917 46.660424-41.34695-98.26153 121.572867-75.794875z"
          fill="#4762AF"
        ></path>
        <path
          d="M469.572424 440.400221c-10.976147-6.337475-27.610249-7.026433-37.11882-1.538359L122.346765 617.901419c-9.508571 5.492793-8.314691 15.095742 2.661456 21.433217l428.617143 247.463521c10.980866 6.337475 27.614968 7.026433 37.123539 1.538359l310.106839-179.039557c9.508571-5.492793 8.314691-15.095742-2.666175-21.433217l-428.617143-247.463521z"
          fill="#6D8ACA"
        ></path>
        <path
          d="M511.443171 825.155244s48.736737 34.849032 78.069382 18.64435c29.332645-16.209401 288.022415-167.860498 288.022415-167.860497l-0.235945-44.277383-365.855852 140.637198v52.856332z"
          fill="#5D7BC1"
        ></path>
        <path
          d="M199.175078 592.008848v52.856332l312.423816 180.379723V772.388571l-312.423816-180.379723z"
          fill="#5D7BC1"
        ></path>
        <path
          d="M373.3353 846.55071c0-6.030747-3.6713-13.047742-8.192-15.657291L157.360959 710.934415c-4.5207-2.614267-8.192 0.160442-8.192 6.191189s3.6713 13.047742 8.192 15.65729l207.782341 119.959005c4.5207 2.614267 8.192-0.160442 8.192-6.191189z"
          fill="#6D8ACA"
        ></path>
        <path
          d="M469.572424 350.259908c-10.976147-6.342194-27.610249-7.031152-37.11882-1.543079L122.346765 527.761106c-9.508571 5.488074-8.314691 15.091023 2.661456 21.428498l428.617143 247.46352c10.980866 6.337475 27.614968 7.026433 37.123539 1.53836l310.106839-179.039558c9.508571-5.488074 8.314691-15.091023-2.666175-21.433216l-428.617143-247.458802z"
          fill="#4762AF"
        ></path>
        <path
          d="M453.301677 664.212645c5.219097 3.015373 9.461382 10.357972 9.461383 16.388719s-4.242286 8.479853-9.461383 5.464479c-5.223816-3.015373-9.461382-10.357972-9.461382-16.388719s4.237567-8.479853 9.461382-5.464479zM491.208553 686.098876c5.219097 3.015373 9.461382 10.357972 9.461382 16.388718s-4.242286 8.475134-9.461382 5.459761c-5.223816-3.010654-9.461382-10.357972-9.461382-16.384 0-6.030747 4.237567-8.479853 9.461382-5.464479zM529.11071 707.985106c5.223816 3.015373 9.461382 10.357972 9.461382 16.388719 0 6.026028-4.237567 8.475134-9.461382 5.45976-5.219097-3.015373-9.461382-10.357972-9.461383-16.388719 0-6.026028 4.242286-8.475134 9.461383-5.45976z"
          fill="#448FFF"
        ></path>
        <path
          d="M116.004571 410.32671s-0.962654 128.495484 0.132129 128.320884c1.090065-0.16988 52.979023 26.741972 52.979024 26.741973l52.922396-53.262157 3.270193-38.237198L116.004571 410.32671zM907.273438 482.412535v127.391262l-80.225917 46.660424-41.34695-98.26153 121.572867-75.790156z"
          fill="#4762AF"
        ></path>
        <path
          d="M469.572424 222.821456c-10.976147-6.337475-27.610249-7.026433-37.11882-1.538359L122.346765 400.322654c-9.508571 5.492793-8.314691 15.095742 2.661456 21.433217l428.617143 247.463521c10.980866 6.337475 27.614968 7.026433 37.123539 1.538359l310.106839-179.039557c9.508571-5.492793 8.314691-15.095742-2.666175-21.433217l-428.617143-247.463521zM373.3353 628.971945c0-6.030747-3.6713-13.047742-8.192-15.657291L157.360959 493.35565c-4.5207-2.614267-8.192 0.160442-8.192 6.191189s3.6713 13.047742 8.192 15.65729L365.1433 635.163134c4.5207 2.614267 8.192-0.160442 8.192-6.191189z"
          fill="#6D8ACA"
        ></path>
        <path
          d="M511.443171 607.897364s48.736737 34.853751 78.069382 18.64435c29.332645-16.204682 288.022415-167.860498 288.022415-167.860497l-0.235945-44.277383-365.855852 140.641917v52.851613z"
          fill="#5D7BC1"
        ></path>
        <path
          d="M199.175078 374.755687v52.856331l312.423816 180.379724v-52.856332L199.175078 374.755687z"
          fill="#5D7BC1"
        ></path>
        <path
          d="M469.572424 132.681143c-10.976147-6.342194-27.610249-7.031152-37.11882-1.543078L122.346765 310.182341c-9.508571 5.488074-8.314691 15.091023 2.661456 21.428498l428.617143 247.46352c10.980866 6.337475 27.614968 7.031152 37.123539 1.53836l310.106839-179.039558c9.508571-5.488074 8.314691-15.091023-2.666175-21.433216l-428.617143-247.458802z"
          fill="#4762AF"
        ></path>
        <path
          d="M453.301677 446.63388c5.219097 3.015373 9.461382 10.357972 9.461383 16.388719s-4.242286 8.479853-9.461383 5.464479c-5.223816-3.015373-9.461382-10.357972-9.461382-16.388719s4.237567-8.479853 9.461382-5.464479zM491.208553 468.520111c5.219097 3.015373 9.461382 10.357972 9.461382 16.388718s-4.242286 8.475134-9.461382 5.459761c-5.223816-3.010654-9.461382-10.357972-9.461382-16.384 0-6.030747 4.237567-8.479853 9.461382-5.464479zM529.11071 490.406341c5.223816 3.015373 9.461382 10.357972 9.461382 16.388719 0 6.026028-4.237567 8.475134-9.461382 5.45976-5.219097-3.015373-9.461382-10.357972-9.461383-16.388719 0-6.026028 4.242286-8.475134 9.461383-5.45976z"
          fill="#448FFF"
        ></path>
        <path
          d="M116.004571 192.747945s-0.962654 128.495484 0.132129 128.320884c1.090065-0.16988 52.979023 26.741972 52.979024 26.741973l52.922396-53.262157 3.270193-38.237198L116.004571 192.747945zM907.273438 264.83377v127.391262l-80.225917 46.660424-41.34695-98.26153 121.572867-75.790156z"
          fill="#4762AF"
        ></path>
        <path
          d="M469.572424 5.242691C458.596276-1.094783 441.962175-1.783742 432.453604 3.704332L122.346765 182.743889c-9.508571 5.492793-8.314691 15.095742 2.661456 21.433217l428.617143 247.463521c10.980866 6.337475 27.614968 7.026433 37.123539 1.538359l310.106839-179.039557c9.508571-5.492793 8.314691-15.095742-2.666175-21.433217L469.572424 5.242691zM373.3353 411.39318c0-6.030747-3.6713-13.047742-8.192-15.657291L157.360959 275.776885c-4.5207-2.614267-8.192 0.165161-8.192 6.191189 0 6.030747 3.6713 13.047742 8.192 15.65729l207.782341 119.963724c4.5207 2.609548 8.192-0.165161 8.192-6.195908z"
          fill="#6D8ACA"
        ></path>
      </g>
    </svg>
  );
};
