import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUserSemanticModels } from '../hooks/useGetUserSemanticModels';
import { useGetUserPlaygrounds } from '../hooks/useGetUserPlaygrounds';
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import {
  createNewPlayground,
  deleteCodePlayground,
} from '../services/playgroundService';
import { IPlaygroundRequestPayload } from '../models/Playground';
import { useAuth } from '../auth/authContext';
import { useNotificationContext } from '../contexts/Notifications';

export const CodePlaygroundList: React.FC = () => {
  const navigate = useNavigate();
  const { userCodePlaygrounds: playgrounds, refreshPlaygrounds } =
    useGetUserPlaygrounds();
  const copyToastElement = (
    <div>
      <div className="alert alert-info">
        <span>Copied to Clipboard</span>
      </div>
    </div>
  );
  const signInToastElement = (
    <div>
      <div className="alert alert-info">
        <span>Sign in to perform this action and more</span>
      </div>
    </div>
  );
  const { copyToClipboard, isCopied } = useCopyToClipboard(copyToastElement);
  const { user } = useAuth();
  const notificationContext = useNotificationContext();
  const showNotification = notificationContext?.showNotification;

  const { userModels, refetch: semanticModelQueryRefetch } =
    useGetUserSemanticModels();

  const onPlaygroundAction = async (type: string, id: number) => {
    switch (type) {
      case 'delete':
        const user_token = await user?.getIdToken();
        if (!user_token) {
          return;
        }
        await deleteCodePlayground(user_token, id);
        await semanticModelQueryRefetch();
        await refreshPlaygrounds();
        return;
      case 'continue':
        return navigate(`/playground/${id}`);
      case 'share':
        copyToClipboard(`https://app.daxsolver.com/playground/${id}`);
        if (isCopied) {
          showNotification?.(copyToastElement);
        }
        return;
      default:
        console.log('Unknown action');
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    playgroundName: '',
    semanticModelId: '',
    description: null,
    public: false,
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    // Special case for radio buttons that have a boolean value
    if (name === 'public') {
      setFormValues((prevValues) => ({
        ...prevValues,
        public: value === 'Public',
      }));
      return;
    }
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const payload: IPlaygroundRequestPayload = {
      name: formValues.playgroundName,
      description: formValues.description,
      model_id: formValues.semanticModelId,
      public: formValues.public,
    };

    // Create new playground
    const authToken = await user?.getIdToken();
    if (!authToken) {
      setIsModalOpen(false);
      showNotification?.(signInToastElement);
      return;
    }
    await createNewPlayground(authToken, payload);
    refreshPlaygrounds(); // Refresh the playgrounds list
    setFormValues({
      playgroundName: '',
      semanticModelId: '',
      description: null,
      public: false,
    });

    setIsModalOpen(false); // Close the modal after form submission
    return;
  };

  return (
    <div className="container mx-auto px-4 py-6 space-y-4">
      <h1 className="text-3xl font-bold text-center dark:text-white pb-6 prose">
        Your Playgrounds
      </h1>
      {/* Row for starting a new session */}
      <div
        className="flex justify-between items-center p-4 border border-dashed border-neutral-content !dark:border-white rounded-md cursor-pointer"
        onClick={() => setIsModalOpen(true)}
      >
        <span className="text-lg font-semibold dark:text-white prose">
          Create a new playground
        </span>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded-md"
          onClick={() => setIsModalOpen(true)}
        >
          New Playground
        </button>
      </div>
      {/* Map through existing playgrounds */}
      <div className="space-y-2">
        {playgrounds.map((playground) => (
          <div
            key={playground.id}
            className="flex justify-between items-center p-4 border border-neutral-content !dark:border-primary rounded-md"
          >
            <span className="text-lg font-semibold dark:text-white prose">
              {playground.name}
              <span className="ml-4 badge badge-primary">
                {playground.public ? 'public' : 'private'}
              </span>
            </span>

            <div className="flex space-x-2">
              {/* Share Button */}
              <button
                className="bg-transparent border border-primary p-2 rounded-md text-primary"
                onClick={() => onPlaygroundAction('share', playground.id)}
              >
                {/* Share Icon */}
                <svg
                  className="w-6 h-6"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="-51.2 -51.2 614.40 614.40"
                  xmlSpace="preserve"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {' '}
                    <path
                      style={{ fill: '#FBB429' }}
                      d="M501.801,213.374L324.879,36.453V142.94h-59.905c-140.708,0-254.775,114.066-254.775,254.775v77.833 l92.966-101.742c52.389-57.335,126.474-89.996,204.14-89.996h17.574v106.487L501.801,213.374z"
                    ></path>{' '}
                    <g>
                      {' '}
                      <path
                        style={{ fill: '#4D4D4D' }}
                        d="M10.197,485.747c-1.238,0-2.488-0.225-3.687-0.691c-3.925-1.523-6.51-5.3-6.51-9.509v-77.833 c0-70.777,27.562-137.318,77.609-187.365c50.047-50.046,116.588-77.609,187.366-77.609h49.705V36.453 c0-4.125,2.486-7.844,6.296-9.423c3.811-1.579,8.198-0.707,11.115,2.21l176.923,176.922c1.912,1.912,2.987,4.507,2.987,7.212 c0,2.705-1.075,5.299-2.987,7.212L332.09,397.509c-2.917,2.916-7.304,3.791-11.115,2.21c-3.81-1.579-6.296-5.297-6.296-9.423 v-96.288h-7.374c-74.616,0-146.278,31.593-196.611,86.677L17.728,482.427C15.758,484.584,13.007,485.747,10.197,485.747z M264.974,153.139c-134.86,0-244.576,109.716-244.576,244.575v51.551l75.237-82.339c54.187-59.303,131.338-93.316,211.669-93.316 h17.573c5.632,0,10.199,4.566,10.199,10.199v81.864l152.299-152.299L335.077,61.076v81.864c0,5.633-4.567,10.199-10.199,10.199 H264.974z"
                      ></path>{' '}
                      <path
                        style={{ fill: '#4D4D4D' }}
                        d="M247.503,190.884c-5.444,0-9.963-4.3-10.184-9.789c-0.227-5.628,4.152-10.375,9.78-10.601 c2.762-0.111,5.571-0.168,8.35-0.168c5.633,0,10.199,4.566,10.199,10.199c0,5.633-4.566,10.199-10.199,10.199 c-2.507,0-5.039,0.051-7.529,0.151C247.781,190.882,247.642,190.884,247.503,190.884z"
                      ></path>{' '}
                      <path
                        style={{ fill: '#4D4D4D' }}
                        d="M140.757,228.2c-3.139,0-6.236-1.444-8.234-4.169c-3.33-4.543-2.348-10.925,2.196-14.255 c22.329-16.37,47.27-27.846,74.131-34.11c5.49-1.279,10.97,2.131,12.249,7.616c1.279,5.486-2.131,10.97-7.616,12.249 c-24.164,5.635-46.607,15.963-66.704,30.696C144.962,227.558,142.85,228.2,140.757,228.2z"
                      ></path>{' '}
                    </g>{' '}
                  </g>
                </svg>
              </button>
              {/* Delete Button */}
              <button
                className="bg-transparent border border-red-500 p-2 rounded-md text-red-500"
                onClick={() => onPlaygroundAction('delete', playground.id)}
              >
                {/* Delete Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              {/* Continue Button */}
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md"
                onClick={() => onPlaygroundAction('continue', playground.id)}
              >
                Continue
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* Modal for creating new playground */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-900 dark:bg-gray-800 p-6 rounded-md shadow-lg w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-white">
              Create New Playground
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Playground Name */}
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Playground Name
                </label>
                <input
                  type="text"
                  name="playgroundName"
                  value={formValues.playgroundName}
                  onChange={handleInputChange}
                  required
                  className="mt-1 block w-full p-2 border border-gray-600 rounded-md focus:outline-none focus:ring focus:border-blue-500 bg-gray-700 text-white"
                />
              </div>

              {/* Semantic Model Dropdown */}
              <div className="flex space-x-2">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-300">
                    Semantic Model
                  </label>
                  <select
                    name="semanticModelId"
                    value={formValues.semanticModelId}
                    onChange={handleInputChange}
                    required
                    className="mt-1 block w-full p-2 border border-gray-600 rounded-md focus:outline-none focus:ring focus:border-blue-500 bg-gray-700 text-white"
                  >
                    <option value="" disabled>
                      Select a model
                    </option>
                    {userModels.map((model) => (
                      <option key={model.modelId} value={model.modelId}>
                        {model.modelName}
                      </option>
                    ))}
                  </select>
                </div>

                <button
                  type="button"
                  className="w-1/3 p-2 mt-6 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-md"
                  onClick={() => {
                    navigate('/manage-models');
                  }}
                >
                  New Model
                </button>
              </div>

              {/* Privacy Option */}
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Playground Privacy
                </label>
                <div className="mt-2">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="public"
                      value="Public"
                      checked={formValues.public == true}
                      onChange={handleInputChange}
                      className="form-radio text-blue-600"
                    />
                    <span className="ml-2 text-gray-300">Public</span>
                  </label>
                  <label className="inline-flex items-center ml-6">
                    <input
                      type="radio"
                      name="public"
                      value="Private"
                      checked={formValues.public == false}
                      onChange={handleInputChange}
                      className="form-radio text-blue-600"
                    />
                    <span className="ml-2 text-gray-300">Private</span>
                  </label>
                </div>
              </div>

              {/* Submit Button */}
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-500 text-white rounded-md"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-green-500 text-white rounded-md"
                >
                  Create Playground
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
