import React, { ReactElement, useState } from 'react';
import { AuthProvider } from './auth/authContext';
import { Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NotificationContext } from './contexts/Notifications';

const queryClient = new QueryClient();

const App: React.FC = () => {
  const [notification, setCurrentNotification] = useState<null | ReactElement>(
    null,
  );
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <NotificationContext.Provider
          value={{
            currentNotification: notification,
            showNotification: (notification: ReactElement | null) => {
              setCurrentNotification(notification);
              console.log(notification);
              setTimeout(() => {
                setCurrentNotification(null);
              }, 5000);
            },
          }}
        >
          <Outlet />
        </NotificationContext.Provider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
