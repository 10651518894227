import React, { useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../auth/authContext';
import { IExecResult } from '../models/ExecutionResult';
import { evaluateSolution } from '../services/questionService';
import { PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Panel } from 'react-resizable-panels';
import { SolverCodeEditorPanelContainer } from '../components/SolverCodeEditorPanel/container';
import { QuestionDescriptionPanelContainer } from '../components/ProblemDescriptionPanel/container';
import { ResultsPanelContainer } from '../components/ResultsPanel/container';
import { editor } from 'monaco-editor';
import { Monaco } from '@monaco-editor/react';
import { SolverContext } from '../contexts/Solver';
import { PanelSeparatorSvg } from '../assets/svgs/PanelSeparator';
import { useGetQuestionInfo } from '../hooks/useGetQuestionInfo';
import { useGetCourseInfo } from '../hooks/useGetCourseInfo';
import Loading from '../components/Loading';
import { useNotificationContext } from '../contexts/Notifications';

const Solver: React.FC = () => {
  const [codeInput, setCodeInput] = useState('// DAX Expression goes here');
  const [results, setResults] = useState<IExecResult | null>(null);
  const { questionId } = useParams<{ questionId: string }>();
  const monacoRef = useRef<Monaco | null>(null);
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  const { data: question, isPending } = useGetQuestionInfo(questionId);
  const { courseInfo } = useGetCourseInfo(
    question?.course_ids ? question.course_ids[0] : undefined,
  );
  const { user } = useAuth();
  const notificationContext = useNotificationContext();
  const showNotification = notificationContext?.showNotification;
  const signInToastElement = (
    <div>
      <div className="alert alert-info">
        <span>Sign in to perform this action and more</span>
      </div>
    </div>
  );

  const handleRun = async (submit: boolean = false) => {
    if (!question) {
      console.error('No question loaded');
      return;
    }
    const authToken = user ? await user.getIdToken() : null;

    if (!authToken) {
      showNotification(signInToastElement);
      return;
    }
    const evaluationResult = await evaluateSolution(
      String(question.id),
      codeInput,
      submit,
      authToken,
    );
    setResults(evaluationResult);
  };

  const handleTablePreviewClick = async (tableName: string) => {
    const authToken = user ? await user.getIdToken() : null;
    if (!question) {
      console.error('No question loaded');
      return;
    }
    const result = await evaluateSolution(
      String(question.id),
      `EVALUATE TOPN (10, ${tableName})`,
      false,
      authToken,
    );
    setResults(result);
  };

  if (isPending) {
    return <Loading fullPage />;
  }
  if (!question) {
    return (
      <div className="flex justify-center items-center h-screen flex-col">
        <p>No question matching that id found</p>
        <Link to="/">
          <button className="mt-4 px-4 py-2 bg-primary text-white rounded hover:bg-blue-700">
            Return to Dashboard
          </button>
        </Link>
      </div>
    );
  }

  return (
    <SolverContext.Provider
      value={{
        question,
        associatedCourseInfo: courseInfo,
        codeInput,
        setCodeInput,
        results,
        editorRef,
        monacoRef,
        onTablePreviewClick: handleTablePreviewClick,
      }}
    >
      {/* Desktop View */}
      <div className="hidden lg:flex">
        <PanelGroup
          direction="horizontal"
          className="lg:w-[calc(100vw-152px)] dax-interactive-main"
          style={{
            height: '100vh',
            padding: '1rem',
            width: 'calc(100vw-120px)',
          }}
        >
          <Panel defaultSize={50} minSize={25}>
            <QuestionDescriptionPanelContainer question={question} />
          </Panel>
          <PanelResizeHandle
            className="flex justify-center items-center lg:flex hidden"
            style={{ width: '20px' }}
          >
            <PanelSeparatorSvg direction={'vertical'} />
          </PanelResizeHandle>
          <Panel
            defaultSize={50}
            minSize={45}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <PanelGroup
              direction="vertical"
              style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <Panel defaultSize={60} minSize={50}>
                <SolverCodeEditorPanelContainer handleRun={handleRun} />
              </Panel>
              <PanelResizeHandle
                className="flex justify-center items-center"
                style={{ height: '20px' }}
              >
                <PanelSeparatorSvg direction={'horizontal'} />
              </PanelResizeHandle>
              <Panel defaultSize={40} minSize={20}>
                <ResultsPanelContainer results={results} />
              </Panel>
            </PanelGroup>
          </Panel>
        </PanelGroup>
      </div>

      {/* Mobile View */}
      <div className="flex flex-col lg:hidden w-screen overflow-auto">
        {/* Question Description Panel */}
        <div className="h-[300px] overflow-y-scroll overflow-scroll p-2">
          <QuestionDescriptionPanelContainer question={question} />
        </div>

        {/* Code Editor Panel */}
        <div className="h-[240px] overflow-y-scroll p-2">
          <SolverCodeEditorPanelContainer handleRun={handleRun} />
        </div>

        {/* Results Panel */}
        <div className="overflow-y-scroll p-2">
          <ResultsPanelContainer results={results} />
        </div>
      </div>
    </SolverContext.Provider>
  );
};

export default Solver;
