import React from 'react';
import { Outlet } from 'react-router-dom';
import { SideNavBar } from '../components/SideNavBar';
import { TopNavBar } from '../components/TopNavBar';
import { useNotificationContext } from '../contexts/Notifications';

const DashboardBase: React.FC = () => {
  const notificationContext = useNotificationContext();
  return (
    <div className="flex min-h-screen text-white bg-base-200">
      <SideNavBar />
      <div className="flex-1 lg:ml-[120px] pb-16 lg:pb-0">
        <main>
          {notificationContext && notificationContext.currentNotification ? (
            <div className="fixed top-4 right-4 z-10">
              {notificationContext.currentNotification}
            </div>
          ) : null}
          <Outlet />
        </main>
      </div>
      <div className="block lg:hidden">
        <TopNavBar />
      </div>
    </div>
  );
};

export default DashboardBase;
