import React from 'react';
import styles from './Loading.module.css';

interface LoadingProps {
  fullPage?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ fullPage = false }) => {
  return (
    <div
      className={`${styles.loadingContainer} ${fullPage ? 'h-screen' : 'h-full'}`}
    >
      <div className={styles.loadingDot}></div>
      <div className={styles.loadingDot}></div>
      <div className={styles.loadingDot}></div>
    </div>
  );
};

export default Loading;
