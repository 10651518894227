import { createContext, ReactElement, useContext } from 'react';

export interface INotificationContext {
  showNotification: (message: ReactElement) => void;
  currentNotification: ReactElement | null;
}

export const NotificationContext = createContext<INotificationContext | null>(
  null,
);
export const useNotificationContext = () => useContext(NotificationContext)!;
