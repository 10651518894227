import { useState, useCallback } from 'react';
import { useNotificationContext } from '../contexts/Notifications';

export const useCopyToClipboard = (withToast: JSX.Element) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = useCallback(
    (text: string) => {
      if (navigator?.clipboard?.writeText) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            setIsCopied(true);
          })
          .catch((err) =>
            console.error('Failed to copy text to clipboard:', err),
          );
      } else {
        console.error('Clipboard API not supported');
      }
    },
    [withToast],
  );

  return { copyToClipboard, isCopied };
};

export default useCopyToClipboard;
